<template>
    <footer>
        <div>
            <div>&copy; {{currentYear}} - {{$t('html.labels.allRightsReserved')}}</div>
        </div>
        <div>
            <router-link :to="{name: 'impressum'}">{{$t('pages.impressum.breadcrumb')}}</router-link>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',

    computed: {
        currentYear() {
            const now = new Date();
            return now.getFullYear();
        }
    }
}
</script>
