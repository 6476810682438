<template>
    <ul class="breadcrumbs-menu">
        <li v-for="fragment in fragments" class="breadcrumbs-menu__fragment">
            <router-link :to="fragment.path ? {path: fragment.path} : {name: fragment.name}">{{ $t(fragment.label) }}</router-link>
        </li>
    </ul>
</template>

<script>

/**
 * This is a very simple implementation. May will fail with complex dynamic route params.
 * Such things are not expected in this project currently.
 */
export default {
    name: "BreadcrumbsMenu",

    computed: {
        fragments() {
            return this.getFilteredFragments();
        }
    },
    
    methods: {
        getFilteredFragments() {
            let breadcrumbs = [];
            const fragments = this.$router.currentRoute.value.matched;
            const params = this.$router.currentRoute.value.params;
            
            breadcrumbs.push({
                name: 'index',
                label: 'pages.index.breadcrumb'
            });

            fragments.forEach((route) => {
                if(route.meta.hidden || !route.meta.label || route.meta.label.length === 0) {
                    return;
                };

                // Simpler solution would be in the template - which will fail when the name property is empty
                // <router-link :to="{name: fragment.name, params: $router.params}">{{ fragment.meta.label }}</router-link>
                let path = route.path;
                Object.keys(this.$router.currentRoute.value.params).forEach((key) => {
                    path = path.replace(`:${key}`, params[key]);
                });

                breadcrumbs.push({
                    path: path,
                    label: route.meta.label
                });
            });
            
            return breadcrumbs;
        }
    }
}
</script>