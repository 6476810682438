import './bootstrap';

import { createApp } from 'vue/dist/vue.esm-bundler';
import * as VueRouter from 'vue-router';
import { createPinia } from 'pinia';
import { useThemesStore } from "./stores/Themes";
import { useLocalizationStore } from "./stores/Localization";

import User from "./plugins/User";
import Formatter from "./plugins/Formatter";
import MainFrame from "./pages/MainFrame.vue";

import commonUrls from "./routes/common";
import guestUrls from "./routes/guest";
import authenticatedUrls from "./routes/authenticated";

const Application = createApp({
    components: { MainFrame },
    template: '<main-frame :class="[ThemesStore.getHtmlClass()]"/>',

    data() {
        return {
            ThemesStore: useThemesStore()
        };
    },

    beforeMount() {
        this.ThemesStore.initialize();
    }
});

Application
    .use(createPinia());

const UserPlugin = new User();

const Router = new VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes: _.concat(commonUrls, UserPlugin.isLoggedIn() ? authenticatedUrls : guestUrls)
});

Router.beforeResolve(async(to, from) => {
    let hasPermission = true;
    to.matched.forEach((Route) => {
        if (!hasPermission) {
            return;
        }

        if (Route.meta.permissions.length) {
            hasPermission = UserPlugin.hasPermission(Route.meta.permissions, { route: { to, from } });
        }
    });

    let hasRole = true;
    to.matched.forEach((Route) => {
        if (!hasRole) {
            return;
        }

        if (Route.meta.roles.length) {
            hasRole = UserPlugin.hasRole(Route.meta.roles, { route: { to, from } });
        }
    });

    if (!hasPermission || !hasRole) {
        return { name: 'http.status.403' };
    }

    return true;
});

const LocalizationStore = useLocalizationStore();

(async() => {
    Application
        .use(UserPlugin)
        .use(Router)
        .use(await LocalizationStore.getI18nPlugin())
        .use(new Formatter());

    // This will be deprecated at VueJs v3.3
    Application.config.unwrapInjectedRef = true;

    // Do not follow blindly, we will set up our own relocation method
    axios.defaults.maxRedirects = 0; // Sets fetch() redirect to "manual"

    // Add a response interceptor
    axios.interceptors.response.use(
        // In case of 2xx
        undefined, // Do nothing with it here
        // Outside of 2xx status
        (error) => {
            switch (error.response.status) {
                case 302: // Transforming relocation order into a valid UI action instead of an error
                    if (error.response.data.accepted) {
                        /**
                         * Vuejs will not perform relocation two times if the original action would be
                         * a relocation too to the same url like in the Login component.
                         * Otherwise double relocation may can happen.
                         */
                        Application.config.globalProperties.$router.replace({
                            path: (error.response.headers.get("content-type") === 'application/json') ?
                                error.response.data.relocate : error.response.data
                        });

                        // This is not an error to be honest but a valid redirection response
                        return Promise.resolve(error.response);
                    }
                    break;
                case 401: // Authentication is required so take the user to the login page
                    Application.config.globalProperties.$router.replace({ name: 'login' });
                    break;
            }

            return Promise.reject(error);
        }
    );

    Application.mount('#application');
})();