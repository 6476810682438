<template>
    <button @click="attemptLogout()"><slot>{{$t('html.buttons.logout')}}</slot></button>
</template>

<script>

export default {
    name: "LogoutButton",
    
    methods: {
        attemptLogout() {
            this.$router.push({name: 'logout'});
        }
    }
}
</script>