<template>
    <LabelledSelect class="rounded-select" :options="options"
        @changed="optionSelected($event)"
        v-model="selected"
        :selector="false">{{ $t('html.labels.themeSelector') }}</LabelledSelect>
</template>

<script>
import { useThemesStore } from "../stores/Themes";
import LabelledSelect from "./inputs/LabelledSelect";

export default {
    name: "ThemeSelector",

    components: { LabelledSelect },

    data: () => {return {
        ThemeStore: useThemesStore(),
        selected: null
    };},

    mounted() {
        this.selected = Object.values(_.pickBy(this.options, (item) => {
            return item.id === this.ThemeStore.current;
        }))[0];
    },

    computed: {
        options() {
            let options = [];

            this.ThemeStore.allowed.forEach((value) => {
                options.push({
                    id: value,
                    label: 'html.themeSelector.' + value
                });
            });

            return options;
        }
    },

    methods: {
        optionSelected(option) {
            this.ThemeStore.setTheme(option.id);
        }
    }
}
</script>
<style scoped>
.rounded-select {
    text-align: center;
}
</style>