<template>
  <div class="frame-main">
    <!--TODO: Egységes fejléc és lábléc alkalmazásának kérdése "frame-main__header"
    További részletek a JIRA jegyben:https://takacs-balint.atlassian.net/browse/TJI21-10-->
    <Header class="frame-main__header" />

    <BreadcrumbsMenu />

    <div class="frame-main__view-container">
      <div class="frame-main__view-container__wrapper">
        <router-view class="frame-main__view-container__wrapper__view" />
      </div>
    </div>

    <Modal ref="Modal" />

    <Footer class="frame-main__footer" />
  </div>
</template>

<script>
import Header from "./Header.vue";
import BreadcrumbsMenu from "../components/BreadcrumbsMenu";
import Modal from "../components/Modal.vue";
import { computed } from "vue";
import Footer from "./Footer.vue";

export default {
  name: "MainFrame",

  components: { Footer, Modal, Header, BreadcrumbsMenu },

  provide() {
    return {
      Modal: computed(() => this.$refs.Modal),
    };
  },
};
</script>
