<template>
  <div class="language-selector rounded-select" style="text-align: center">
    <LabelledSelect
      :options="options"
      :translate="false"
      :selector="false"
      v-model="selected"
      @changed="optionSelected"
      >{{ $t("html.labels.languageSelector") }}</LabelledSelect
    >
  </div>
</template>

<script>
import { useLocalizationStore } from "../stores/Localization";
import LabelledSelect from "./inputs/LabelledSelect";

export default {
  name: "LanguageSelector",

  components: { LabelledSelect },

  data: () => {
    return {
      LocalizationStore: useLocalizationStore(),
      selected: null,
    };
  },

  mounted() {
    this.selected = Object.values(
      _.pickBy(this.options, (item) => {
        return item.id === this.LocalizationStore.current;
      })
    )[0];
  },

  computed: {
    options() {
      let options = [];

      _.forOwn(this.LocalizationStore.allowed, (value, key) => {
        options.push({
          id: key,
          label: this.LocalizationStore.allowed[key],
        });
      });

      return options;
    },
  },

  methods: {
    optionSelected(option) {
      this.LocalizationStore.setLocale(option.id);
    },
  },
};
</script>
<style scoped>
.rounded-select {
    text-align: center;
}
</style>