export default class UserModel {
    constructor(props) {
        this.uuid = props.uuid;
        this.id = props.id;
        this.name = props.name;
        this.roles = props.roles;
        this.permissions = props.permissions;
        this.resource = props.api.resource;
    }
}
