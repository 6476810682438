import { defineStore } from 'pinia';
import Cookies from 'js-cookie';
import { createI18n } from 'vue-i18n';

const cookieName = 'Accept-Language';

export const useLocalizationStore = defineStore('Stores.Localization', {
    state: () => ({
        current: Cookies.get(cookieName),
        fallback: window.frontendConfiguration.vueJs.localization.preferred ??
            window.frontendConfiguration.vueJs.localization.fallback ?? 'hu',
        history: [],
        // TODO: Valósíts meg egy mechanizmust, amely megfigyeli és frissíti az aktuális nyelvi beállítást tartalmazó sütit az alkalmazás inicializálásakor, biztosítva, hogy az érvényesség lejárta előtt frissüljön, a felhasználói élmény javítása érdekében. További részletek a JIRA jegyben: https://takacs-balint.atlassian.net/browse/TJI21-16
        expire: 400, // days (https://httpwg.org/http-extensions/draft-ietf-httpbis-rfc6265bis.html#name-the-expires-attribute)
        allowed: window.frontendConfiguration.vueJs.localization?.allowed ?? {hu: 'magyar'},
        i18n: null
    }),

    actions: {
        async setLocale(locale) {
            if(!this.allowed.hasOwnProperty(locale)) {
                console.error(`Localization key "${locale}" is not allowed!`);
                return;
            }

            if(!this.i18n.global.availableLocales.includes(locale)) {
                let Module = await import(`../localization/${locale}.js`);

                this.i18n.global.setLocaleMessage(locale, Module.default);
            }

            if(locale !== this.current) {
                let history = this.history.slice(); // .slice() works perfectly here!
                history.push(this.current);

                this.$patch({
                    current: locale,
                    history: history
                });

                if (this.i18n.mode === 'legacy') {
                    this.i18n.global.locale = locale;
                }
                else {
                    this.i18n.global.locale.value = locale;
                }

                Cookies.set(cookieName, locale, {
                    expires: this.expire
                });
                document.documentElement.setAttribute('lang', locale);
            }

            return this;
        },

        async getI18nPlugin() {
            if(!this.i18n) {
                const env = {
                   silentTranslationWarn: import.meta.env.VITE_VUEJS_I18N_SUPPRESS_WARNING_TRANSLATION?.toLowerCase() === 'true',
                   silentFallbackWarn: import.meta.env.VITE_VUEJS_I18N_SUPPRESS_WARNING_FALLBACK?.toLowerCase() === 'true'
                };

                this.i18n = createI18n({
                    messages: {},
                    locale: this.current,
                    fallbackLocale: this.fallback,
                    silentTranslationWarn: env.silentTranslationWarn,
                    silentFallbackWarn: env.silentFallbackWarn,
                    formatFallbackMessages: true
                });

                await this.setLocale(this.current ?? this.fallback);
            }

            return this.i18n;
        }
    }
});