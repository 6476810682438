<template>
    <div class="modal" :class="[opened ? '-opened' : '-closed']" @click="close">
        <div class="modal__container" @click.prevent>
            <div class="modal__container__message">{{message}}</div>
            <div class="modal__container__buttons">
                <slot name="buttons">
                    <button @click="close">{{$t('html.buttons.closeModal')}}</button>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Modal",

    data() {return {
        message: '',
        opened: false
    };},

    methods: {
        setMessage(message) {
            this.message = message;

            return this;
        },

        open(message) {
            if(message) {
                this.setMessage(message);
            }

            this.opened = true;
            this.$emit('opened');

            return this;
        },

        close() {
            this.opened = false;
            this.message = '';
            this.$emit('closed');

            return this;
        }
    }
}
</script>
