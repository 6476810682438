<template>
    <div class="labelled-select">
        <label>
            <div v-if="this.$slots.default" class="labelled-select__title" style="padding:5px">
                <slot></slot>
            </div>
            <select v-model="value" :disabled="disabled">
                <option v-if="selector" selected disabled>{{ shouldTranslateSelector ? $t(selector) : selector }}</option>
                <option v-for="option in options"
                    :value="option"
                    >{{ translate && option.label ? $t(option.label) : option.label || '-'}}</option>
            </select>
        </label>
        <aside v-if="hasError" class="labelled-input__errors">
            <ul>
                <li v-for="error in validator.$errors">{{ error.$message }}</li>
            </ul>
        </aside>
    </div>
</template>

<script>

import LabelledInput from "./LabelledInput.vue";

export default {
    name: 'LabelledSelect',
    components: {LabelledInput},

    props: {
        options: {
            type: Array,
            required: true,
            default: [],
        },
        placeholder: {
            type: String,
            required: false,
            default: 'html.inputs.select.placeholder',
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        validator: {
            required: false,
            type: Object,
            default: null
        },
        translate: {
            type: Boolean,
            required: false,
            default: true,
        },
        selector: {
            type: [Boolean, String],
            required: false,
            default: 'html.labels.selectOneOption'
        },
        translateSelector: {
            type: Boolean,
            required: false,
            default: true
        },
        modelValue: {
            required: false,
            default: null
        }
    },

    methods: {
        selectOption($event) {console.log($event.target, $event.target.value);
            //let value = this.options[$event.target.value];

            this.$emit('selected', $event.target.value);
        }
    },

    computed: {
        shouldTranslateSelector() {
            return this.translateSelector && this.selector;
        },

        classObject() {
            return {
                '-pristine': this.validator && !this.validator.$dirty,
                '-error': this.validator && this.validator.$dirty && this.hasError,
                '-correct': this.validator && this.validator.$dirty && !this.hasError,
            };
        },

        hasError() {
            return this.validator && this.validator.$dirty && this.validator.$errors.length > 0;
        },

        value: {
            get() {
                return this.modelValue;
            },

            set(newValue) {
                this.$emit('update:modelValue', newValue);
                this.$emit('changed', newValue);
            }
        }
    }
};
</script>
