<template>
  <div>
    <div class="frame-main__header__content">
      <div class="frame-main__header__content__title">
        <router-link :to="{ path: '/' }">
          <h1>{{ $t("html.labels.webpageTitle") }}</h1>
        </router-link>
      </div>

      <div v-if="$user.isLoggedIn()" class="user-info">
        <div class="user-info-item">
          {{ $t("pages.login.loggedinuser") }}: {{ $user.name }}
        </div>
        <br />
        <div class="user-info-item">
          {{ $t("pages.login.SZTSZ") }}: {{ $user.resource.data.sztsz || "nem ismert" }}
        </div>
        <br />
        <div class="user-info-item">
          {{ $t("pages.login.Organization") }}:
          {{ $user.resource.data.szervezet || "nem ismert" }}
        </div>
        <br />
        <div class="user-info-item">Adószám: Jelenleg nem ismert</div>
        <br />
        <div class="user-info-item">Munkakör: Jelenleg nem ismert</div>
        <br />
        <div class="user-info-item" v-if="roles.length > 0">
          <div v-for="role in roles" :key="role.id">
            {{ $t("pages.login.Role") }}: {{ getRoleTranslation(role.name) }}
          </div>
        </div>
      </div>
    </div>
    <div class="frame-main__header__buttons">
      <ThemeSelector />
      <LanguageSelector />
      <LogoutButton v-if="$user.isLoggedIn()" />
    </div>
  </div>
</template>

<script>
import ThemeSelector from "../components/ThemeSelector";
import LogoutButton from "../components/buttons/LogoutButton";
import LanguageSelector from "../components/LanguageSelector";

export default {
  data() {
    return {
      translationMap: {
        "System.Administrator": "Rendszergazda",
        "DecisionMaker.Superior": "Szervezeti egység vezetője",
        "DecisionMaker.SZTOK": "Döntéshozó SZTOK",
        "DecisionMaker.EEGFI": "Döntéshozó EEGFI",
        "DecisionMaker.Principal": "Döntéshozó Elnök",
        User: "Felhasználó",
      },
      roles: [],
    };
  },
  name: "Header",

  components: {
    ThemeSelector,
    LogoutButton,
    LanguageSelector,
  },
  mounted() {
    if (this.$user.isLoggedIn()) {
      //console.log(this.$user.resource.data);
      const rolesData = this.$user.resource.data.roles;
      if (rolesData) {
        //console.log("Szerepkörök tartalma:", rolesData);
        this.roles = Object.values(rolesData).map((role) => role.data);
        //console.log("Szerepkörök tömbje:", this.roles);
      } else {
        //console.log("A roles adat nem elérhető.");
      }
    } else {
      //console.log("A felhasználó nincs bejelentkezve.");
    }
  },
  methods: {
    getRoleTranslation(roleName) {
      return this.translationMap[roleName] || roleName;
    },
  },
};
</script>
<style scoped>
.user-info {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.3rem;
  justify-content: flex-start;
}

.user-info-item {
  box-sizing: border-box;
  font-size: 0.875rem;
  color: #555;
  border-bottom-style: double;
  margin-left: 10px;
}
</style>