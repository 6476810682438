import {useLocalizationStore} from "../stores/Localization";
import {
    format as dateFnsFormat,
    formatDistance as dateFnsFormatDistance,
    getWeek as dateFnsGetWeek,
    getISODay as dateFnsGetISODay,
} from 'date-fns';
import { enGB as en, hu } from 'date-fns/locale';

export default class Formatter{
    constructor() {
        this.LocaleStore = useLocalizationStore();
        this.config = {
            formats: {
                date: 'yyyy MMMM d HH:mm:ss'
            },
            locales: {en, hu}
        };
    }

    install(app, options) {
        const exposed = {
            date: this.date.bind(this),
            fromNow: this.fromNow.bind(this),
            filesize: this.filesize.bind(this),
            getWeek: this.getWeek.bind(this),
            getWeekDay: this.getWeekDay.bind(this),
        };

        /**
         * Example:
         * this.$root.$formatter
         */
        app.config.globalProperties.$formatter = exposed;

        /**
         * Example:
         * import { inject } from 'vue';
         *
         * const Formatter = inject('Formatter')
         */
        app.provide('Formatter', exposed);
    }

    _getLocale() {
        return this.config.locales[this.LocaleStore.current];
    }

    _isEmpty(value) {
        return value === 0 || !value;
    }

    date(date, format) {
        if(this._isEmpty(date)) {
            return;
        }

        return dateFnsFormat(new Date(date), format ? format : this.config.formats.date, { locale: this._getLocale() });
    }

    fromNow(date) {
        if(this._isEmpty(date)) {
            return;
        }

        return dateFnsFormatDistance(new Date(date), new Date(), { locale: this._getLocale(), addSuffix: true });
    }

    filesize(bytes, decimals) {
        if(bytes == 0) {
            return '0 b';
        }

        let kilo = 1024,
            decimalsLength = decimals || 2,
            sizes = ['b', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'],
            index = Math.floor(Math.log(bytes) / Math.log(kilo));

        return parseFloat((bytes / Math.pow(kilo, index)).toFixed(decimalsLength)) + ' ' + sizes[index];
    }

    /**
     * Returns the week number of a given date. Respects ISO 8601 standard which says
     * the first week of a year is the very first monday in the given year.
     *
     * @param {Date} date
     * @returns
     */
    getWeek(date) {
        if(! (date instanceof Date)) {
            console.error('Invalid first argument!');
        }

        let WeekDayOfFirstOfJanuary = this.getWeekDay(new Date(`${date.getFullYear()}-01-01`));

        let weekNumber = dateFnsGetWeek(date, {
            weekStartsOn: 1, // Monday
            firstWeekContainsDate: WeekDayOfFirstOfJanuary
        });


        /**
         * There is a possible bug in date-fns library (2023.05.19): First days of january before the
         * first monday are always reported as week 52 even if there are 53 numbered week in the
         * previous year.
         *
         * TODO: This things should be tested more thoughtfully
         */
        if(weekNumber === 52 && date.getMonth() === 0 && this.getWeekDay(date) >= WeekDayOfFirstOfJanuary ) {
            // Correcting the week number
            weekNumber = dateFnsGetWeek(
                new Date(`${date.getFullYear() - 1}-12-31`),
                {weekStartsOn: 1, firstWeekContainsDate: this.getWeekDay(new Date(`${date.getFullYear() - 1}-01-01`))}
            );
        }

        return weekNumber;
    }

    getWeekDay(date) {
        if(! (date instanceof Date)) {
            console.error('Invalid first argument!');
        }

        // Where 1 is monday
        return dateFnsGetISODay(date);
    }
};
