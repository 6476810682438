class Route {
    constructor(parameters) {
        this.name = parameters.name ?? null;
        this.path = parameters.path ?? null;
        this.component = parameters.component ?? null;
        this.children = parameters.children ?? null;
        this.beforeResolve = parameters.beforeResolve ?? null;
        this.beforeEnter = parameters.beforeEnter ?? null;

        this.meta = {
            hidden: Boolean(parameters.hidden ?? false),
            roles: parameters.roles ?? [],
            permissions: parameters.permissions ?? [],
            label: parameters.label ?? null
        };
    }
}

class RedirectRoute {
    constructor(parameters) {
        this.name = parameters.name ?? null;
        this.path = parameters.path ?? null;
        this.redirect = parameters.redirect;

        this.meta = {
            hidden: Boolean(parameters.hidden ?? true),
            roles: parameters.roles ?? [],
            permissions: parameters.permissions ?? [],
            label: parameters.label ?? null,
            authenticationRequired: true
        };
    }
}

export {
    Route,
    RedirectRoute
};
